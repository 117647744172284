<template>
  <el-dialog
    v-el-drag-dialog
    :visible.sync="dialogVisible"
    :close-on-click-modal="false"
    title="新增消息"
    width="500px"
    top="3vh"
    :modal="false"
    @close="$reset('form')">
    <el-form
      ref="form"
      v-loading="contentLoading"
      :model="model"
      class="form"
      :label-width="$l('user.labelWidth','80px')">
      <el-form-item label="类型" prop="type">
        <vm-dict-select v-model="model.type" type="noticeType"></vm-dict-select>
      </el-form-item>
      <el-form-item :label="$l('user.title','标题')" prop="title" :rules="$rule.notNull">
        <el-input v-model="model.title" :placeholder="$l('common.enter','请输入')"></el-input>
      </el-form-item>

      <el-form-item
        :label="$l('login.content','内容')"
        prop="content"
        :rules="[$rule.notNull]">
        <el-input
          v-model="model.content"
          :placeholder="$l('common.enter','请输入')"
          type="textarea"
          show-word-limit
          maxlength="100"
        ></el-input>
      </el-form-item>
      <!-- <el-form-item :label="$l('user.type','用户类型')" prop="type" :rules="$rule.notNull">
        <vm-dict-select v-model="model.type" type="userType"></vm-dict-select>
      </el-form-item> -->
    </el-form>
    <span slot="footer">
      <el-button @click="dialogVisible=false">{{$l("common.cancel", "取消")}}</el-button>
      <el-button :loading="submitLoading" type="primary" @click="handleSubmit">{{$l("common.save", "保存")}}</el-button>
    </span>
  </el-dialog>
</template>

<script>
  import loginUtil from "@/util/loginUtil";

  export default {
    components: {},
    data() {
      return {
        dialogVisible: false,
        contentLoading: false,
        submitLoading: false,
        roles: [],
        checkedRoleIds: [],
        currUserType: loginUtil.getUserType(),
        showUserTypes: null,
        model: {
          id: 0,
          phone: "",
          password: "",
          name: "",
          userType: "",
          storeId: "",
          storeName: "",
          clientId: "",
          clientName: "",
        },
      };
    },
    methods: {
      open(id, userType) {
        this.model.id = id;
        if (this.currUserType === 2) {
          this.showUserTypes = [3, 4];
        } else if (this.currUserType === 3) {
          if (userType === 3) {
            this.$message.error("无权限编辑");
            return;
          }
          this.showUserTypes = [4];
          this.model.userType = 4;
        }
        this.dialogVisible = true;
        this.getData();
      },
      async getData() {
        this.contentLoading = true;
        this.roles = await this.$http.get("authentication/role/list");
        if (this.model.id) {
          this.model = await this.$http.get(`authentication/user/${this.model.id}`);
          for (let item of this.model.roles) {
            this.checkedRoleIds.push(item.id);
          }
        } else {
          if (this.currUserType === 3) {
            this.model.storeName = loginUtil.getStoreName();
            this.model.storeId = -1;
          }
        }
        this.contentLoading = false;
      },
      storeSelect(row) {
        this.model.storeId = row.id;
        this.model.storeName = row.storeName;
      },
      clientSelect(row) {
        this.model.clientId = row.id;
        this.model.clientName = row.clientName;
      },
      handleSubmit() {
        this.$refs.form.validate(valid => {
          if (valid) {
            this.submitLoading = true;
            this.model.roles = this.checkedRoleIds.map(item => ({id: item}));
            this.$http
              .save("basic/system-notice-message", this.model)
              .then(() => {
                this.submitLoading = false;
                this.dialogVisible = false;
                this.$emit("save-success");
                this.$message.success("保存成功");
              })
              .catch(() => {
                this.submitLoading = false;
              });
          }
        });
      },
    },
  };
</script>

<style lang="scss" scoped>
</style>
