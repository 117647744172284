<template>
  <div>
    <vm-table
      ref="vmTable"
      :filter.sync="filter"
      url="basic/system-notice-message/page">
      <el-button slot="toolbar" type="primary" size="mini" @click="$refs.editPage.open(0)">
        {{$l("common.add", "新增")}}
      </el-button>
      <template slot="adSearch">
        <div class="vm-search">
          <vm-search :label="$l('user.name','标题')">
            <el-input v-model.trim="filter.name" clearable></el-input>
          </vm-search>
        </div>
      </template>
      <el-table-column align="center" type="index" width="50"></el-table-column>
      <el-table-column prop="title" :label="$l('user.phone','标题')" align="center"></el-table-column>
      <el-table-column prop="createBy" :label="$l('user.name','操作人')" align="center"></el-table-column>
      <el-table-column prop="content" :label="$l('user.name','内容')" align="center"></el-table-column>
      <el-table-column prop="typeDesc" :label="$l('user.name','类型')" align="center"></el-table-column>
      <el-table-column prop="createTime" :label="$l('user.name','创建时间')" align="center"></el-table-column>
    </vm-table>
    <info-add ref="editPage" @save-success="getList(-1)"></info-add>
  </div>
</template>
<script>
  import InfoAdd from "@/views/system/InfoAdd";
  export default {
    components:{InfoAdd},
    data() {
      return {
        filter: {
          phone: "",
          name: "",
          type: "",
          status: "",
          orderColumnName: "id",
          asc: 0,
        },
      };
    },
    mounted() {
      this.getList(1);
    },
    methods: {
      getList(pageNum) {
        this.$refs.vmTable.getList(pageNum);
      },


    },
  };
</script>
<style lang="scss" scoped>
</style>
